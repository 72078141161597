import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import Swish from '../../images/betalningslosningar/Swish_Logo.png'
import Billecta from '../../images/betalningslosningar/billecta.png'
import Resursbank from '../../images/betalningslosningar/resursbank.jpg'
const style = {
  color: '#161c2d',
  textDecoration: 'none'
}

const Betalningslosningar = () => (
  <Layout>
    <Seo title='Betalningslösningar' />
    <PageHeader text='Våra betalningslösningar' />
    <div className='container'>
      <div className='row justify-content-around'>

        <div className='col-7 col-sm-6 col-md-5 col-lg-2 col-xl-2 p-0  text-center'>
          <img src={Swish} alt='Swish' className='img-fluid rounded' />
          <h4 className='mt-0 pt-0'>Swish</h4>
          <p>123 690 8370</p>
        </div>

        <div className='col-7 col-sm-6 col-md-5 col-lg-2 col-xl-2 p-0  text-center'>
          <img src={Billecta} alt='billecta' className='img-fluid rounded' />
          <h4 className='mt-0 pt-0'>Fakturering</h4>
          <p>Genom Bilecta via STR</p>
        </div>

        <div className='col-7 col-sm-6 col-md-5 col-lg-2 col-xl-2 p-0 text-center'>
          <img src={Resursbank} alt='resursbank' className='img-fluid rounded' />
          <Link to='/info/resursbank' style={style}>
            <h4 className='mt-0 pt-0'>Avbetalning</h4>
            <p> <u>Läs mer här</u></p>
          </Link>
        </div>

        <div className='col-7 col-sm-6 col-md-5 col-lg-2 col-xl-2 p-0  text-center'>
          <span className='fa-stack fa-2x'>
            <FontAwesomeIcon icon={faCircle} className='fa-stack-2x' color='rgb(172, 3, 11)' />
            <FontAwesomeIcon icon={faCreditCard} className='fa-stack-1x fa-inverse' />
          </span>

          <h4 className='mt-2 pt-0'>Kortbetalning</h4>
          <p>Observera att vi inte tar amex</p>
        </div>

      </div>
    </div>
  </Layout>
)

export default Betalningslosningar
